<template>
  <div class="signup page">
    <!-- 이용동의 -->
    <SignupAgree v-if="!agree" @clickConfirm="clickConfirm" />

    <!-- 회원가입 정보 입력 -->
    <SignUpForm v-else @clickCancelBtn="clickCancelBtn" />
  </div>
</template>

<script>
import SignUpForm from './SignUpForm_v2.vue';
import SignupAgree from './SignupAgreement.vue';
export default {
  components: { SignUpForm, SignupAgree },
  data() {
    return {
      agree: false,
    };
  },
  mounted() {},
  methods: {
    clickConfirm() {
      this.agree = true;
    },
    clickCancelBtn() {
      this.agree = false;
    },
  },
};
</script>

<template>
  <div class="signup-form">
    <h3 class="page-header">HCP 인증</h3>
    <a-form-model ref="signupForm" :model="formValue" :rules="rules">
      <a-form-model-item ref="name" label="이름" prop="name">
        <a-input ref="inputName" v-model="formValue.name" type="text" size="large" placeholder="이름" />
      </a-form-model-item>

      <a-form-model-item ref="email" label="이메일" prop="email">
        <a-input v-model="formValue.email" type="email" size="large" placeholder="이메일" />
      </a-form-model-item>

      <a-form-model-item ref="mobile" label="휴대전화번호" prop="mobile">
        <a-input v-model="formValue.mobile" type="text" size="large" placeholder="휴대전화번호" />
      </a-form-model-item>

      <a-form-model-item ref="hospital" label="병원명" prop="hospital">
        <a-input v-model="formValue.hospital" type="text" size="large" placeholder="병원명" />
      </a-form-model-item>

      <a-form-model-item ref="hospitalAddress" :label="addressLbl" prop="hospitalAddress">
        <a-input v-model="formValue.hospitalAddress" type="text" size="large" placeholder="병원 주소" />
      </a-form-model-item>
      <a-form-model-item
        v-if="!isHarnaldiChannel"
        ref="hospitalAddressDetail"
        label="상세주소"
        prop="hospitalAddressDetail"
      >
        <a-input v-model="formValue.hospitalAddressDetail" type="text" size="large" placeholder="상세주소" />
      </a-form-model-item>

      <a-form-model-item ref="MR" label="담당자명" prop="MR">
        <a-input v-model="formValue.MR" type="text" size="large" placeholder="담당자명" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button
          type="primary"
          size="large"
          block
          :disabled="
            !formValue.name ||
            !formValue.mobile ||
            !formValue.email ||
            !formValue.hospital ||
            !formValue.hospitalAddress
          "
          @click="clickSubmit"
        >
          인증하기
        </a-button>
      </a-form-model-item>
    </a-form-model>

    <!-- 병원 검색 팝업 -->
    <ModalSearchHospital ref="ModalSearchHospital" @hospitalSelectComplete="hospitalSelectCompleteHandler" />

    <!-- 회원가입 정보 확인 -->
    <ModalCheckInfo :inputData="formValue" @confirm="popupConfirm" ref="ModalCheckInfo" />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import ModalCheckInfo from './modal/ModalCheckInfo.vue';
import ModalSearchHospital from './modal/ModalSearchHospital.vue';

import { nameRule, emailRule, mobileRule } from '@/utils/rules';

export default {
  components: {
    ModalCheckInfo,
    ModalSearchHospital,
  },
  data() {
    return {
      // form values
      formValue: {
        type: 'USER',
        agree1: 'Y',
        agree2: 'Y',
        name: '',
        email: '',
        mobile: '',
        hospital: '',
        hospitalAddress: '',
        hospitalAddressDetail: '',
        MR: '',
      },
      rules: {
        name: [
          { required: true, message: '이름을 입력해주세요.', trigger: 'blur' },
          { validator: nameRule, trigger: 'change' },
        ],
        email: [
          { required: true, message: '이메일을 입력해주세요.', trigger: 'blur' },
          { validator: emailRule, trigger: 'change' },
        ],
        mobile: [
          { required: true, message: '휴대전화번호를 입력해주세요.', trigger: 'blur' },
          { validator: mobileRule, trigger: 'change' },
        ],
        hospital: [{ required: true, message: '병원병을 입력해주세요.', trigger: 'blur' }],
        hospitalAddress: [{ required: true, message: '병원 주소를 입력해주세요.', trigger: 'blur' }],
      },
      isHarnaldiChannel: false,
      addressLbl: '',
    };
  },
  mounted() {
    // 채널확인
    this.isHarnaldiChannel = this.kakaoInfo.kakaoChannel === '하루날베타미가봇' ? true : false;
    this.addressLbl = this.kakaoInfo.kakaoChannel === '하루날베타미가봇' ? '병원 주소(시,구까지 기재)' : '병원 주소';
    console.log('', this.kakaoInfo.kakaoChannel);

    // return;
    // this.formValue.name = '테스트';
    // this.formValue.email = 't@mail.com';
    // this.formValue.mobile = '01012341234';
    this.formValue.hospital = '';
    this.formValue.hospitalAddress = '';
    this.formValue.hospitalAddressDetail = '';
    this.formValue.isHcp = 'W';
    this.formValue.MR = '';

    // 개발시
    // if (process.env.NODE_ENV === 'development') {
    //   this.formValue.name = '테스트';
    //   this.formValue.email = 't@mail.com';
    //   this.formValue.mobile = '01012341234';
    //   this.formValue.hospital = '테스트';
    //   this.formValue.hospitalAddress = '서울';
    //   this.formValue.hospitalAddressDetail = '강남';
    //   this.formValue.isHcp = 'W';
    //   this.formValue.MR = '';
    // }

    setTimeout(() => {
      this.$refs.inputName.focus();
    }, 500);
  },
  methods: {
    changeDate(value) {
      this.birthday = value;
    },
    clickCancelBtn() {
      this.$emit('clickCancelBtn');
    },
    clickSubmit() {
      // 유효성 체크
      this.$refs.signupForm.validate((valid) => {
        console.log('', valid);
        if (valid) {
          // 가입정보 확인 팝업
          this.$refs.ModalCheckInfo.Open();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    popupConfirm() {
      //가입 정보 확인 완료
      this.formValue.kakaoChannel = this.kakaoInfo.kakaoChannel;
      this.formValue.kakaoId = this.kakaoInfo.kakaoId;
      this.formValue.mobile4info = this.formValue.mobile === '' ? '' : this.formValue.mobile.substr(-4);
      // console.log(this.formValue);
      // return;
      this.signup(this).then((status) => {
        if (status !== 201) {
          // alert('회원가입 에러');
          return;
        }

        // TODO 회원가입후 알람 처리
        this.$router.push('/home?kakaoChannel=' + this.kakaoInfo.kakaoChannel + '&kakaoId=' + this.kakaoInfo.kakaoId);
      });
    },
    hospitalSearch() {
      this.$refs.ModalSearchHospital.Open();
    },
    hospitalSelectCompleteHandler(e) {
      console.log('', e);

      this.formValue.hospital = e.dutyName;
      this.formValue.hospitalAddress = e.dutyAddr;
      this.formValue.hospitalAddressDetail = e.dutyMapimg;
    },
    ...mapActions(['signup']),
  },
  computed: {
    ...mapState(['kakaoInfo']),
  },
};
</script>

<style lang="scss">
.signup-form {
  padding-top: 65px;
  .ant-col {
    button.ant-btn {
      min-width: 100%;
    }
  }
}
.click-layer {
  position: absolute;
  height: 50px;
  width: 100%;
  z-index: 500;
}
.singup-wrap {
  padding: 40px 20px 80px;
  .singup-logo-wrap {
    // padding: 40px;
    text-align: center;
    img {
      height: 60px;
      // margin-left: -23px;
    }
  }
  h1 {
    color: $primary-color;
  }
  .singup-form-button {
    // background: $primary-color;
    // border-color: $primary-color;
    // color: #ffffff;
    width: 49%;
    // margin-top: 30px;
    &:last-child {
      // margin-top: 10px;
      margin-left: 2%;
    }
  }
}
</style>

export const requiredRule = (value) => {
    return !!value || "Required.";
};

export const phoneRule = (value) => {
    const number = value && value.replaceAll("-", "") || "";
    return number.length === 10 || number.length === 0 || "Invalid number form.";
};

// export const mobileRule = (value) => {
//   const number = value && value.replaceAll("-", "") || "";
//   return (number.length > 9 && number.length < 12) || number.length === 0 || "Invalid number form.";
// };

export const faxRule = (value) => {
    const number = value && value.replaceAll("-", "") || "";
    return number.length === 10 || number.length === 0 || "Invalid number form.";
};

export const onlyNumber = (number) => {
    const pattern = /^\d+$/;
    return pattern.test(number) || "Only numbers are allowed";
};
export const onlyNumberAndDash = (number) => {
    const pattern = /[\d -]+/;
    return pattern.test(number) || number === '' || "Only numbers are allowed";
};
export const onlyAlphabet = (val) => {
    const pattern = /^[A-Z]+$/i;
    return pattern.test(val) || "Only alphabets are allowed";
};
// 소수점 포함한 숫자 허용
export const onlyNumberAndDot = (number) => {
    const pattern = /^(\d{1,2})([.]\d{0,2}?)?$/;
    return pattern.test(number) || "Only enter up to two decimal places less than 100.";
};

// export const emailRule = (value) => {
//   const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   return pattern.test(value) || "Invalid e-mail form.";
// };

export let nameRule = (rule, value, callback) => {
    const pattern = /^[ㄱ-ㅎ|가-힣]+$/;
    if (!pattern.test(value)) {
        callback(new Error('이름은 한글만 입력해주세요.'));
    } else {
        callback();

    }
};

export let emailRule = (rule, value, callback) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!pattern.test(value)) {
        callback(new Error('이메일 형식에 맞게 입력해주세요.'));
    } else {
        callback();
    }
};

export let mobileRule = (rule, value, callback) => {
    const pattern = /^\d+$/;
    if (!pattern.test(value)) {
        callback(new Error('휴대전화번호는 숫자만 입력해주세요.'));
    } else {
        if (value.length < 10 || value.length > 11) {
            callback(new Error('휴대전화번호를 정확히 입력해주세요.'));
        }
        callback();
    }
};

export let birthdayRule = (rule, value, callback) => {
    const pattern = /^\d+$/;
    if (!pattern.test(value)) {
        callback(new Error('생년월일은 숫자만 입력해주세요.'));
    } else {
        if (value.length < 8 || value.length > 8) {
            callback(new Error('생년월일은 8자리로 입력해주세요.'));
        }
        callback();
    }
};

export let licenseNumberRule = (rule, value, callback) => {
    const pattern = /^\d+$/;
    if (!pattern.test(value)) {
        callback(new Error('면허번호는 숫자만 입력해주세요.'));
    } else {
        callback();
    }
};


// export const mobileRule = (value) => {
//   const number = value && value.replaceAll("-", "") || "";
//   return (number.length > 9 && number.length < 12) || number.length === 0 || "Invalid number form.";
// };

// 비밀번호 Rule
export const pwRule = (str) => {
    //8~15 영문숫자특수문자중 두개이상 혼합
    if (str == undefined)
        return "대소문자와 특수문자 포함한 최소 8자리를 입력하세요.";

    var pw = str;

    var num = pw.search(/[0-9]/g);

    var eng = pw.search(/[a-z]/g);
    var ueng = pw.search(/[A-Z]/g);

    var spe = pw.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);

    if (pw.length < 8) {
        return "대소문자와 특수문자 포함한 최소 8자리를 입력하세요.";
    }

    if (pw.search(/₩s/) != -1) {
        return "대소문자와 특수문자 포함한 최소 8자리를 입력하세요.";
    }
    let counter = 0;
    if (num > -1) {
        counter++;
    }
    if (eng > -1) {
        counter++;
    }
    if (ueng > -1) {
        counter++;
    }
    if (spe > -1) {
        counter++;
    }

    if (counter < 4) {
        return "대소문자와 특수문자 포함한 최소 8자리를 입력하세요.";
    }

    return true;
};

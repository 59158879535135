<template>
  <a-modal v-model="visible" title="병원 검색" :footer="null" dialogClass="search-hospital">
    <p><b>병원명을 검색해주세요.</b><br />정확한 지역 및 병원명을 입력해주세요.</p>
    <div class="row">
      <SelectBox
        ref="refCityCombo"
        :selectValue="selectedCity"
        :searchList="selectCity"
        @updateSelect="cityUpdateSelect"
      />
    </div>
    <div class="row">
      <SelectBox :selectValue="selectedGugun" :searchList="selectGugun" @updateSelect="GugunUpdateSelect" />
    </div>
    <div class="row">
      <a-input
        ref="hospitalName"
        v-model="hospitalName"
        type="text"
        size="large"
        placeholder="병원명을 입력해주세요."
      />
    </div>
    <div class="row">
      <a-button type="primary" size="large" block @click="handleOk"> 병원 검색 </a-button>
    </div>

    <div class="result-list">
      <a-list item-layout="horizontal" :data-source="hospitalData">
        <a-list-item slot="renderItem" slot-scope="item, index">
          <a-list-item-meta>
            <div slot="title" @click="hospitalListClick(item)">{{ item.dutyName }}</div>
          </a-list-item-meta>
        </a-list-item>
      </a-list>
    </div>

    <!-- 스피너 -->
    <LoadingSpinner v-if="isLoading" />
  </a-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SelectBox from '@/components/common/SelectBoxWithLabel.vue';
import LoadingSpinner from '@/components/common/LoadingSpinner.vue';

const formatService = require('@/utils/format.js');

export default {
  components: {
    SelectBox,
    LoadingSpinner,
  },
  props: {},
  data() {
    return {
      visible: false,
      isLoading: false,
      selectCity: formatService.reginCity(),
      selectedCity: '',
      selectGugun: formatService.reginGugunDefault(),
      selectedGugun: '',
      hospitalName: '',
      hospitalData: [],
    };
  },
  methods: {
    Open() {
      this.visible = true;

      setTimeout(() => {
        this.$refs.hospitalName.focus();
      }, 500);
    },
    cityUpdateSelect(e, key) {
      this.selectedCity = key.key;
      this.selectedGugun = '';
      this.selectGugun = formatService.reginGugunDefault();
      let tArray = formatService[e]();
      let tObject = [];
      tArray.forEach((element) => {
        const d = {
          value: element,
          label: element,
        };
        tObject.push(d);
      });
      this.selectGugun = tObject;
    },
    GugunUpdateSelect(e) {
      this.selectedGugun = e;

      setTimeout(() => {
        this.$refs.hospitalName.focus();
      }, 500);
    },
    handleOk(e) {
      // 필수처리
      const RF = this.$refs;
      if (!this.$helper.requiredCheck(this.selectedCity, RF.refCityCombo, `도시를 선택해주세요`, false)) return;
      if (!this.$helper.requiredCheck(this.hospitalName, RF.hospitalName, `병원명을 입력해주세요`)) return;

      this.isLoading = true;
      // this.hospitalName = '가정';
      this.getHospital(this).then((res) => {
        if (res) {
          let item = res.response.body.items.item;
          if (0 < item.length) {
            // console.log('', item);
            this.hospitalData = item;
          }
        }
        this.isLoading = false;
      });
    },
    hospitalListClick(e) {
      this.$emit('hospitalSelectComplete', e);
      this.visible = false;
    },
    ...mapActions(['getHospital']),
  },
  computed: {
    ...mapState(['hospital']),
  },
};
</script>

<style lang="scss">
.row {
  margin-bottom: 15px;
}
.ant-modal.search-hospital {
  .ant-modal-body {
    height: calc(100% - 54px);
  }
}
.result-list {
  margin-top: 40px;
  height: calc(100% - 343px);
  overflow: auto;
  .ant-list-item {
    padding: 10px 15px;
  }
}
</style>
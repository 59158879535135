<template>
  <div class="agree">
    <div class="logo-wrap">
      <img src="@/assets/img/astellas_logo.svg" alt="" />
    </div>
    <h3 class="page-header">개인정보수집이용 및 <br />광고성 정보 전송 동의서</h3>
    <!-- <p><strong class="sub-title">본 카카오톡 채널을 추가해주셔서 감사합니다.</strong></p> -->
    <p>
      본 카카오톡 채널은 보건의료전문가에 대한 전문적 정보 제공을 목적으로 하는 채널로서, 전문의약품의 대중광고를 엄격히
      금지하는 약사법 기타 관계법령을 준수합니다. 이에 현재 유효한 의사면허를 소지하고 계신 점이 확인된 보건의료전문가
      분들께만 정보 제공이 가능합니다. 확인 절차에 영업일기준 3일이 소요될 수 있음을 양해하여 주시기 바라며, 최대한
      빠르게 처리해드리겠습니다.
    </p>
    <p>
      한국아스텔라스제약㈜ (이하 “회사”)은 자사 의약품의 설명 및 관련 의∙약학적인 정보를 신속하고 원활하게 제공하기 위해
      보건의료전문가의 개인정보를 관련 법령에 의거하여 아래와 같이 수집 및 활용합니다
    </p>
    <div class="check-wrap">
      <div class="check-row">
        <a-checkbox v-model="agreeCheck.all" @change="checkAll">
          <strong>전체 동의</strong>
        </a-checkbox>
      </div>
      <div class="check-row">
        <div class="cont">
          <h4>1. 마케팅 목적의 개인정보수집 및 이용에 대한 동의</h4>
          <p>
            <b>수집하는 개인정보의 항목:</b> 이름, 병원(직장)명, 주소, 전화/휴대폰번호, 이메일 주소, (해당되는 경우)
            카카오톡채널 내에서 수집된 정보- 방문 횟수 및 기록 <br />
            <b>개인정보의 수집·이용 목적:</b>
            <u
              > 카카오 채널을 통하여 (1)회사 제품과 관련된 의·약학적 정보 및 이벤트진행 알림, 뉴스레터, 제품설명회 소개,
              초청장/사전등록링크 전송 등 정보 전달 (2)제품 및 업무관련 자료 및 물품의 배송 (3)법적, 행정적 의무이행을
              위한 관련자료/안내서 등의 배포 (4)보건의료전문가를 대상으로 하는 마케팅, 시장조사, 설문조사 등 영업 마케팅
              및 서비스 품질 관리 목적 및 귀하의 선호 등을 반영한 맞춤형 정보 및 서비스 제공 목적.</u
            ><br />
            <b>개인정보의 보유·이용기간:</b>
            <u
              >관계 법령의 규정에 따라 귀하의 개인 정보를 보존하여야 하는 경우가 아닌 한, 귀하의 개인정보는 귀하가
              카카오 채널을 탈퇴하는 시점까지 보유 및 이용됩니다.</u
            >
            <br />
            귀하는 위와 같은 마케팅 목적의 개인정보 수집 및 이용에 대해 동의를 거부할 수 있습니다. 다만, 이에 동의하지
            않을 경우 카카오 채널 가입 및 정보제공이 이루어지지 않을 수 있습니다.
          </p>
        </div>
        <a-radio-group v-model="agreeCheck.require1" @change="onChange">
          <a-radio :value="true">
            <strong>위와 같은 마케팅 목적 개인정보 수집∙이용에 동의합니다. <span>(필수)</span></strong>
          </a-radio>
          <a-radio :value="false">
            <strong>동의하지 않습니다.</strong>
          </a-radio>
        </a-radio-group>
      </div>
      <div class="check-row">
        <div class="cont">
          <h4>2. 광고성 정보 수신 관련 동의</h4>
          <p>
            회사는 보건의료전문가를 대상으로 폐사 제품 및 질환 관련하여 채널을 통해 카드 뉴스, 브로셔 등의 디테일링
            자료나 심포지엄 안내 및 초청을 통해 최신의 정보를 제공해드리고자 합니다. <br />
          </p>
        </div>
        <a-radio-group v-model="agreeCheck.require2" @change="onChange">
          <a-radio :value="true">
            <strong>위와 같은 광고성 정보 수신에 동의합니다.<span>(필수)</span></strong>
          </a-radio>
          <a-radio :value="false">
            <strong>동의하지 않습니다.</strong>
          </a-radio>
        </a-radio-group>
      </div>
      <!-- <div class="check-row">
        <div class="cont">
          <h4>3. 마케팅 목적의 개인정보 이용에 관한 동의</h4>
          <p>
            위의 수집 이용에 동의하여 주신 개인정보를
            <u>
              전화, SMS, 카카오톡, 이메일, 우편을 통하여 (1)회사 제품과 관련된 의·약학적 정보 및 이벤트진행 알림,
              제품설명회 소개, 초청장 전송 등 정보 전달 (2)제품 및 업무관련 자료 및 물품의 배송 (3)법적, 행정적
              의무이행을 위한 관련자료/안내서 등의 배포 (4)보건의료전문가를 대상으로 하는 마케팅, 시장조사, 설문조사 등
              영업 마케팅 및 서비스 품질 관리 목적
            </u>
            등으로 사용합니다.
          </p>
        </div>
        <a-radio-group v-model="agreeCheck.require3" @change="onChange">
          <a-radio :value="true">
            <strong>마케팅 목적 개인정보 이용에 동의합니다. <span>(필수)</span></strong>
          </a-radio>
          <a-radio :value="false">
            <strong>동의하지 않습니다.</strong>
          </a-radio>
        </a-radio-group>
      </div> -->
      <div class="check-row">
        <div class="cont">
          <h4>3. 개인정보의 수탁, 제3자 제공 및 국외이전에 관한 동의:</h4>
          <table>
            <tr>
              <th>
                <u>제공/이전받는 자 (이전국가/연락처)</u>
              </th>
              <td>
                <u><b>ZP LAB (02-2006-0860)</b></u>
              </td>
              <td>
                <u><b>회사의 본사 및 계열회사</b></u> <br />
                [계열회사현황:
                <a href="https://www.astellas.com/en/worldwide" target="(astellas)"
                  >https://www.astellas.com/en/worldwide</a
                >./]
              </td>
            </tr>
            <tr>
              <th>제공/이전받는 자가 소재하는 국가</th>
              <td>대한민국</td>
              <td>본사: 일본 계열회사: 미국, 오스트리아 등 43여개국</td>
            </tr>
            <tr>
              <th>제공/이전하는 개인정보 항목</th>
              <td>이름 이메일 연락처(전화/ 휴대폰 번호) 소속 병원병 병원주소 등 수집된 정보 일체</td>
              <td>이름 이메일 연락처(전화/ 휴대폰 번호) 소속 병원병 병원주소 등 수집된 정보 일체</td>
            </tr>
            <tr>
              <th>
                <u> <b>제공/이전받는 자의 개인정보 이용목적</b></u>
              </th>
              <td><b>해당 카카오채널의 시스템 운영 </b></td>
              <td>
                <b>
                  온라인 멀티채널 마케팅시스템 관련 서비스제공과 데이터분석 의학적 문의 및 안전성/품질보고 시스템의
                  관리목적, 계열사 간의 정보공유(본 동의서 1에 기재된 처리목적 일체) 등
                </b>
              </td>
            </tr>
            <tr>
              <th>
                <u>보유 및 이용기간</u>
              </th>
              <td><b>위 1항 기재 기간</b></td>
              <td>
                <b>위 1항 기재 기간</b>
              </td>
            </tr>
          </table>

          <p>
            귀하는 상기 개인정보의 수탁, 제3자 제공 및 국외이전을 거부할 수 있습니다. 다만, 개인정보의 필수적 이전에
            동의하지 않을 경우 정보제공 및 프로그램의 참여가 어려울 수 있습니다.
          </p>
        </div>
        <a-radio-group v-model="agreeCheck.require3" @change="onChange">
          <a-radio :value="true">
            <strong>위와 같은 개인정보 수탁 및/또는 제3자 제공에 동의합니다.<span>(필수)</span></strong>
          </a-radio>
          <a-radio :value="false">
            <strong>동의하지 않습니다.</strong>
          </a-radio>
        </a-radio-group>
        <br />
        <br />
        <a-radio-group v-model="agreeCheck.require4" @change="onChange">
          <a-radio :value="true">
            <strong>위와 같은 개인정보 국외이전에 동의합니다.<span>(필수)</span></strong>
          </a-radio>
          <a-radio :value="false">
            <strong>동의하지 않습니다.</strong>
          </a-radio>
        </a-radio-group>
      </div>
    </div>
    <div class="button-row">
      <a-button id="agreement_submit" class="btn" type="primary" size="large" @click="confirm">동의하기</a-button>
      <a-button id="agreement_cancel" class="btn" size="large" @click="cancel">취소</a-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
export default {
  data() {
    return {
      agreeCheck: {
        all: true,
        require1: true, // 약관 필수
        require2: true, // 약관 필수
        require3: true, // 약관 필수
        require4: true // 약관 필수
      }
    };
  },
  methods: {
    checkAll(e) {
      // console.log(`checked = ${e.target.checked}`);
      const T = this.agreeCheck;
      for (let i = 0; i < 5; i++) {
        T['require' + i] = e.target.checked;
      }
    },
    onChange(e) {
      console.log(e);

      // 동의하지 않음 선택 시 아래 토스트 팝업 오픈
      if (!e.target.value) {
        Vue.$toast.open({
          message: '약관에 동의하지 않을 경우, 한국아스텔라스제약에서 제공하는 정보를 확인하실 수 없습니다.',
          type: 'default',
          duration: 1000,
          dismissible: true
        });
        return;
      }
    },

    confirm() {
      // 필수 체크
      const T = this.agreeCheck;
      if (!T.require1 || !T.require2 || !T.require3 || !T.require4) {
        Vue.$toast.open({
          message: '약관 동의에 대한 모든 내용은 필수입니다.',
          type: 'default',
          duration: 2000,
          dismissible: true
        });
        return;
      }
      // if (this.$store.getters.isLogin.isUser > 0) {
      //   Vue.$toast.open({
      //     message: '이미 존재하는 계정입니다.',
      //     type: 'default',
      //     duration: 1000,
      //     dismissible: true,
      //   });
      //   return;
      // }

      // console.log(this.agreeCheck);
      this.$emit('clickConfirm');
    },
    cancel() {
      location.href = 'kakaotalk://inappbrowser/close';
    }
  }
};
</script>

<style lang="scss">
.agree {
  // position: relative;
  // top: 20%;
  display: inline-block;
  // height: 930px;
  // width: 810px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  text-align: left;
  padding-top: 85px;
  h1 {
    color: $primary-color;
  }
  .sub-title {
    span {
      color: #cb2e3c;
    }
  }
  .check-wrap {
    margin-top: 30px;
    border: 2px solid #717171;
    border-width: 1px 0;

    .person-row {
      padding-right: 5px !important;
    }
    .check-row {
      padding: 15px 0;
      border-top: 1px dotted #aaa;
      &:first-child {
        border: none;
        // strong {
        //   color: #c50f3c;
        // }
      }
      .cont {
        margin: 0 0 15px;
        padding: 10px 10px;
        height: 150px;
        overflow-y: scroll;
        font-size: 13px;
        line-height: 1.5;
        color: #444;
        text-align: justify;
        border: solid 1px #ddd;
        h4 {
          margin-top: 5px;
          font-size: 15px;
          line-height: 1.2;
          color: #333;
        }
        p {
          margin-top: 10px;
          font-size: 13px;
          line-height: 1.8;
          letter-spacing: -1px;
          u {
            font-weight: bolder;
            font-size: 15px;
          }
        }
        table {
          border-top: 1px solid #ddd;
          border-right: 1px solid #ddd;
          margin-bottom: 1rem;
          th {
            background: #f8f8f8;
            border-top: 1px solid #ddd;
            border-collapse: collapse;
            word-break: keep-all;
          }
          th,
          td {
            border-bottom: 1px solid #ddd;
            border-left: 1px solid #ddd;
            padding: 0.5rem;
            font-size: 13px;
            text-align: left;
          }
          td {
            b {
              font-size: 15px;
            }
          }
        }
      }
      label.ant-checkbox-wrapper {
        // float: right;
        margin-right: 10px;
      }
      .link {
        // text-align: right;
        margin: 10px 0 0 27px;
        a {
          text-decoration: underline;
          color: $primary-color;
        }
      }
      p {
        margin: 15px 0;
      }
    }
  }

  .button-row {
    margin-top: 40px;
    text-align: center;
    .btn.cancel {
      background: #fff;
      border-color: #717171;
      color: #717171;
    }
  }
}

@media (max-width: 576px) {
  div.agree {
    height: initial;
    width: 100%;
    border-radius: 0;
    padding-top: 85px;
    // font-size: 13px;
    .check-wrap {
      .check-row {
        padding: 15px 0;
        .btn {
          background: #fff;
        }
        strong {
          line-height: 23px;
        }
        .option {
          float: none;
          padding: 5px 0 20px;
        }
        .buttons {
          margin: 0;
        }
      }
    }
    .button-row {
      .btn {
        width: 100%;
        margin: 0;
        margin-bottom: 8px;
      }
    }
  }
}
</style>

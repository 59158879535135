<template>
  <a-modal v-model="visible" title="가입 정보 확인" @ok="handleOk" headerBg="#f9f9f9" footerBg="#f9f9f9">
    <a-list :split="true">
      <a-list-item>
        <a-col span="6">이름</a-col>
        <a-col class="titleTxt" span="18">{{ inputData.name }}</a-col>
      </a-list-item>
      <a-list-item>
        <a-col span="6">이메일</a-col>
        <a-col class="titleTxt" span="18">{{ inputData.email }}</a-col>
      </a-list-item>
      <a-list-item>
        <a-col span="6">휴대전화</a-col>
        <a-col class="titleTxt" span="18">{{ inputData.mobile }}</a-col>
      </a-list-item>
      <a-list-item>
        <a-col span="6">병원정보</a-col>
        <a-col class="titleTxt" span="18">
          {{ inputData.hospital }}<br />
          {{ inputData.hospitalAddress }}<br />
          {{ inputData.hospitalAddressDetail }}</a-col
        >
      </a-list-item>
      <a-list-item>
        <a-col span="6">담당자명</a-col>
        <a-col class="titleTxt" span="18">{{ inputData.MR }} </a-col>
      </a-list-item>
    </a-list>

    <!-- 동일내용 description 으로 구현한 코드 -->
    <!-- <a-descriptions bordered>
      <a-descriptions-item label="이름">
        {{ inputData.name }}
      </a-descriptions-item>
      <a-descriptions-item label="이메일">
        {{ inputData.email }}
      </a-descriptions-item>
      <a-descriptions-item label="휴대전화번호">
        {{ inputData.mobile }}
      </a-descriptions-item>
      <a-descriptions-item label="병원정보">
        {{ inputData.hospital }}<br />
        {{ inputData.hospitalAddress }}<br />
        {{ inputData.hospitalAddressDetail }}
      </a-descriptions-item>
    </a-descriptions> -->
  </a-modal>
</template>

<script>
export default {
  props: {
    inputData: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    Open() {
      this.visible = true;
    },
    handleOk(e) {
      console.log(e);
      this.visible = false;
      this.$emit('confirm');
    },
  },
};
</script>

<style>
.titleTxt {
  font-weight: 600;
}
</style>
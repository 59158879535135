exports.bbsType = () => {
    let reData = [
        { value: '', label: '[게시판형식]' },
        { value: 'notice', label: '공지사항' },
        { value: 'bbs', label: '광고게시판' },
        { value: 'banner', label: '배너관리' },
    ];
    return reData;
};

exports.reginCity = () => {
    let reData = [
        { value: '', label: '[지역]' },
        { label: "서울특별시", name: "서울특별시", value: "Seoul" },
        { label: "세종특별자치시", name: "세종특별자치시", value: "Sejong" },
        { label: "인천광역시", name: "인천광역시", value: "Incheon" },
        { label: "대전광역시", name: "대전광역시", value: "Daejeon" },
        { label: "광주광역시", name: "광주광역시", value: "Gwangju" },
        { label: "대구광역시", name: "대구광역시", value: "Daegu" },
        { label: "울산광역시", name: "울산광역시", value: "Ulsan" },
        { label: "부산광역시", name: "부산광역시", value: "Busan" },
        { label: "경기도", name: "경기도", value: "Gyeonggi" },
        { label: "강원도", name: "강원도", value: "Gangwon" },
        { label: "충청북도", name: "충청북도", value: "Chungbuk" },
        { label: "충청남도", name: "충청남도", value: "Chungnam" },
        { label: "전라북도", name: "전라북도", value: "Jeonbuk" },
        { label: "전라남도", name: "전라남도", value: "Jeonnam" },
        { label: "경상북도", name: "경상북도", value: "Gyeongbuk" },
        { label: "경상남도", name: "경상남도", value: "Gyeongnam" },
        { label: "제주특별자치도", name: "제주특별자치도", value: "Jeju" },
    ]
    return reData;
};

exports.reginGugunDefault = () => {
    let reData = [
        { value: '', label: '[시/구/군 선택]' },
    ]
    return reData;
};

exports.Seoul = () => {
    let reData = [
        "강남구",
        "강동구",
        "강북구",
        "강서구",
        "관악구",
        "광진구",
        "구로구",
        "금천구",
        "노원구",
        "도봉구",
        "동대문구",
        "동작구",
        "마포구",
        "서대문구",
        "서초구",
        "성동구",
        "성북구",
        "송파구",
        "양천구",
        "영등포구",
        "용산구",
        "은평구",
        "종로구",
        "중구",
        "중랑구"
    ]

    return reData;
};

exports.Incheon = () => {
    let reData = [
        "계양구",
        "남구",
        "남동구",
        "동구",
        "부평구",
        "서구",
        "연수구",
        "중구",
        "강화군",
        "옹진군"
    ]

    return reData;
};

exports.Daejeon = () => {
    let reData = ["대덕구", "동구", "서구", "유성구", "중구"]
    return reData;
};

exports.Gwangju = () => {
    let reData = ["광산구", "남구", "동구", "북구", "서구"]
    return reData;
};

exports.Daegu = () => {
    let reData = ["남구",
        "달서구",
        "동구",
        "북구",
        "서구",
        "수성구",
        "중구",
        "달성군"]
    return reData;
};

exports.Ulsan = () => {
    let reData = ["남구", "동구", "북구", "중구", "울주군"]
    return reData;
};

exports.Busan = () => {
    let reData = ["강서구",
        "금정구",
        "남구",
        "동구",
        "동래구",
        "부산진구",
        "북구",
        "사상구",
        "사하구",
        "서구",
        "수영구",
        "연제구",
        "영도구",
        "중구",
        "해운대구",
        "기장군"]
    return reData;
};

exports.Gyeonggi = () => {
    let reData = ["고양시",
        "과천시",
        "광명시",
        "광주시",
        "구리시",
        "군포시",
        "김포시",
        "남양주시",
        "동두천시",
        "부천시",
        "성남시",
        "수원시",
        "시흥시",
        "안산시",
        "안성시",
        "안양시",
        "양주시",
        "오산시",
        "용인시",
        "의왕시",
        "의정부시",
        "이천시",
        "파주시",
        "평택시",
        "포천시",
        "하남시",
        "화성시",
        "가평군",
        "양평군",
        "여주군",
        "연천군"]
    return reData;
};

exports.Gangwon = () => {
    let reData = ["강릉시",
        "동해시",
        "삼척시",
        "속초시",
        "원주시",
        "춘천시",
        "태백시",
        "고성군",
        "양구군",
        "양양군",
        "영월군",
        "인제군",
        "정선군",
        "철원군",
        "평창군",
        "홍천군",
        "화천군",
        "횡성군"]
    return reData;
};

exports.Chungbuk = () => {
    let reData = ["제천시",
        "청주시",
        "충주시",
        "괴산군",
        "단양군",
        "보은군",
        "영동군",
        "옥천군",
        "음성군",
        "증평군",
        "진천군",
        "청원군"]
    return reData;
};

exports.Chungnam = () => {
    let reData = ["계룡시",
        "공주시",
        "논산시",
        "보령시",
        "서산시",
        "아산시",
        "천안시",
        "금산군",
        "당진군",
        "부여군",
        "서천군",
        "연기군",
        "예산군",
        "청양군",
        "태안군",
        "홍성군"]
    return reData;
};

exports.Jeonbuk = () => {
    let reData = ["군산시",
        "김제시",
        "남원시",
        "익산시",
        "전주시",
        "정읍시",
        "고창군",
        "무주군",
        "부안군",
        "순창군",
        "완주군",
        "임실군",
        "장수군",
        "진안군"]
    return reData;
};

exports.Jeonnam = () => {
    let reData = [
        "광양시",
        "나주시",
        "목포시",
        "순천시",
        "여수시",
        "강진군",
        "고흥군",
        "곡성군",
        "구례군",
        "담양군",
        "무안군",
        "보성군",
        "신안군",
        "영광군",
        "영암군",
        "완도군",
        "장성군",
        "장흥군",
        "진도군",
        "함평군",
        "해남군",
        "화순군"]
    return reData;
};

exports.Gyeongbuk = () => {
    let reData = [
        "경산시",
        "경주시",
        "구미시",
        "김천시",
        "문경시",
        "상주시",
        "안동시",
        "영주시",
        "영천시",
        "포항시",
        "고령군",
        "군위군",
        "봉화군",
        "성주군",
        "영덕군",
        "영양군",
        "예천군",
        "울릉군",
        "울진군",
        "의성군",
        "청도군",
        "청송군",
        "칠곡군"
    ]
    return reData;
};

exports.Gyeongnam = () => {
    let reData = [
        "거제시",
        "김해시",
        "마산시",
        "밀양시",
        "사천시",
        "양산시",
        "진주시",
        "진해시",
        "창원시",
        "통영시",
        "거창군",
        "고성군",
        "남해군",
        "산청군",
        "의령군",
        "창녕군",
        "하동군",
        "함안군",
        "함양군",
        "합천군"
    ]
    return reData;
};

exports.Jeju = () => {
    let reData = ["서귀포시", "제주시", "남제주군", "북제주군"]
    return reData;
};

exports.Sejong = () => {
    let reData = ["조치원읍",
        "연기면",
        "연동면",
        "부강면",
        "금남면",
        "장군면",
        "연서면",
        "전의면",
        "전동면",
        "소정면",
        "한솔동",
        "새롬동",
        "나성동",
        "도담동",
        "어진동",
        "해밀동",
        "아름동",
        "종촌동",
        "고운동",
        "소담동",
        "반곡동",
        "보람동",
        "대평동",
        "다정동",
    ]
    return reData;
};

<template>
  <a-select :value="selectValue" @change="onChange" :default-value="''">
    <a-select-option ct-option v-for="item in searchList" :value="item.value" :key="item.label">
      {{ item.label }}
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  props: {
    selectValue: {
      type: [Number, String],
      default: null,
    },
    searchList: {
      // [{value: '', label: ''}
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onChange(value, key) {
      this.updateSelect(value, key);
    },
    updateSelect(value, key) {
      this.$emit('updateSelect', value, key);
    },
  },
};
</script>

<style>
</style>